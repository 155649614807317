@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base{
  @font-face{
      font-family: "MonaFace";
      src: url('https://mona-fonts.s3.ap-southeast-2.amazonaws.com/monafacelift-regular-webfont.woff');
  }

  @font-face{
      font-family: "BaseBloom";
      src: url('https://mona-fonts.s3.ap-southeast-2.amazonaws.com/mofo2022/BASEBLOOM-Regular.otf');
  }

  @font-face{
      font-family: "SpaceMono";
      src: url("https://mona-fonts.s3.ap-southeast-2.amazonaws.com/mofo2022/SpaceMono-Bold.ttf");
      font-weight: bold;
  }

  @font-face{
      font-family: "SpaceMono";
      src: url("https://mona-fonts.s3.ap-southeast-2.amazonaws.com/mofo2022/SpaceMono-BoldItalic.ttf");
      font-weight: bold;
      font-style: italic;
  }

  @font-face{
      font-family: "SpaceMono";
      src: url("https://mona-fonts.s3.ap-southeast-2.amazonaws.com/mofo2022/SpaceMono-Italic.ttf");
      font-style: italic;
  }

  @font-face{
      font-family: "SpaceMono";
      src: url("https://mona-fonts.s3.ap-southeast-2.amazonaws.com/mofo2022/SpaceMono-Regular.ttf");
  }

  @font-face{
      font-family: 'Epilouge';
      src: url("https://mona-fonts.s3.ap-southeast-2.amazonaws.com/mofo2022/Epilogue-Regular.ttf");
  }

  @font-face {
      font-family: 'Epilouge';
      src: url("https://mona-fonts.s3.ap-southeast-2.amazonaws.com/mofo2022/Epilogue-Bold.ttf");
      font-weight: bold;
  }

  @font-face {
      font-family: 'Epilouge';
      src: url("https://mona-fonts.s3.ap-southeast-2.amazonaws.com/mofo2022/Epilogue-Italic.ttf");
      font-style: italic;
  }

  @font-face {
      font-family: 'Epilouge';
      src: url("https://mona-fonts.s3.ap-southeast-2.amazonaws.com/mofo2022/Epilogue-BoldItalic.ttf");
      font-weight: bold;
      font-style: italic;
  }

  @font-face {
    font-family: "MonaFaceLift";
    src: url("https://s3-ap-southeast-2.amazonaws.com/mona-fonts/monafacelift-regular-webfont.woff2")
        format("woff2"),
      url("https://s3-ap-southeast-2.amazonaws.com/mona-fonts/monafacelift-regular-webfont.woff")
        format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "MonaFaceLift_Bold";
    src: url("https://s3-ap-southeast-2.amazonaws.com/mona-fonts/monafacelift-bold-webfont.woff2")
        format("woff2"),
      url("https://s3-ap-southeast-2.amazonaws.com/mona-fonts/monafacelift-bold-webfont.woff")
        format("woff");
    font-weight: normal;
    font-style: normal;
  }

  body{
      @apply bg-yellow;
  }


  h1{
      font-family: 'SpaceMono';
      @apply md:text-9xl text-6xl font-bold;
  }
  h2{
      font-family: "SpaceMono";
      @apply text-3xl font-bold;
  }
  h3{
      @apply text-xl font-bold;
  }

  h4{
      @apply font-bold text-xl my-2
  }

  a {
    text-decoration: underline;
  }
  a:focus,
  a:hover {
    outline: #d5277f solid 0.2em;
    outline-offset: 0.2em;
  }

  p + p{
      @apply mt-4;
  }
}

/* We must use literal CSS for MapboxGL not Tailwind apply directives which
 * don't work for the map widget's dynamically inserted HTML
 */
.mapboxgl-popup-close-button{
    /* text-xl */
    font-size: 1.25rem;
    line-height: 1.75rem;
    /* pr-2 */
    padding-right: 0.5em;
}

.mapboxgl-popup-content{
    /* bg-yellow */
    background-color: #ffd800;
    /* text-black */
    color: black;
    padding-right: 25px;
}

.mapboxgl-popup-content a:focus{
    outline: none;
}

.mapboxgl-popup-content p + p{
    margin: 0;
}

.mapboxgl-popup{
}

.markdown ul {
    /* list-disc */
    list-style-type: disc;
    /* list-inside */
    list-style-position: inside;
}

.markdown ul li{
    /* my-2 */
    margin: 0.5em 0;
}

.markdown ul + p{
    margin-top: 1rem;
}

.markdown table{
    @apply my-4;
}

.markdown td{
    @apply px-2;
}
