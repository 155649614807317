/* Global CSS resets
 * =================
 *
 * Try to use CSS modules or in-JSX CSS to style pages and components
 * instead of adding styles here.
 *
 * If you need to tweak global styles for a specific page do something like:
 *
 *   <style jsx global>{`
 *     body {
 *       background: pink;
 *     }
 *   `}</style>
 */

html,
body {
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
p,
ul {
  margin: 0;
}

ul {
  padding: 0;
  list-style: none;
}

button {
  padding: 0.5rem 1rem;
  font-weight: bold;
}
